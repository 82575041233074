import EnrolmentsGrid from "../EnrolmentsGrid";
import React from "react";
import deltas from "../../../redux/actions/deltas";
import {connect} from "react-redux";
import CheckboxField from "../../atoms/CheckboxField";
import SelectInput from "../../atoms/SelectInput";
import signals from "../../../redux/actions/signals";
import roles from "../../../redux/roles";
import {createSelector} from 'reselect';
import EnrolmentsAndCohortsPollingAlertMessage from "../../molecules/EnrolmentsAndCohortsPollingAlertMessage";
import {GRID_FILTER_TYPE_DATE, GRID_FILTER_TYPE_NUMERIC} from "../../molecules/GridFilter";

export const allColumns = [
    { accessor: 'id', Header: 'Id', gridFilterType: GRID_FILTER_TYPE_NUMERIC },
    { accessor: 'fullName', Header: 'Full Name' },
    { accessor: 'email', Header: 'Email' },
    { accessor: 'phone', Header: 'Phone' },
    { accessor: 'town', Header: 'Town' },
    { accessor: 'schoolName', Header: 'School' },
    { accessor: 'cohortName', Header: 'Cohort' },
    { accessor: 'courseName', Header: 'Course' },
    { accessor: 'providerName', Header: 'Provider' },
    { accessor: 'regionName', Header: 'Course Region' },
    { accessor: 'deliveryTypeName', Header: 'Training Delivery Type'},
    { accessor: 'signedUpAt', Header: 'Sign Up Date', gridFilterType: GRID_FILTER_TYPE_DATE },
    { accessor: 'isContractIssued', Header: 'Contract Issued' },
    { accessor: 'isContractSigned', Header: 'Contract Signed' },
    { accessor: 'contractSignedAt', Header: 'Contract Signed At', gridFilterType: GRID_FILTER_TYPE_DATE },
    { accessor: 'completedAt', Header: 'Completed At', gridFilterType: GRID_FILTER_TYPE_DATE },
    { accessor: 'status', Header: 'Status' },
];

const columnsProviderDoesNotSeeByKey = [
    'id',
    'providerName',
];

export const cecColumns = allColumns;
export const providerColumns = allColumns.filter(column => !columnsProviderDoesNotSeeByKey.includes(column.accessor));

const {
    hideEnrolmentsGridColumn,
    showEnrolmentsGridColumn,
    showAllEnrolmentsGridColumns,
    resetEnrolmentsGridFilterAndSort,
    clearEnrolmentsGridRowsSelected,
    setEnrolmentsGridAllVisibleRowsSelected,
} = deltas.actionCreators;

const {
    downloadEnrolmentsCsv,
} = signals.actionCreators;

const getAreAllVisibleRowsSelected = createSelector(
    state => state.enrolmentsGrid.filteredSortedData,
    state => state.enrolmentsGrid.selectedRows,
    (filteredSortedData, selectedRows) => filteredSortedData.map(row => row.id).every(id => selectedRows.includes(id)));

const getBaseColumns = createSelector(
    state => state.role,
    role => (roles.CEC === role) ? cecColumns : providerColumns,
);

const getColumnsToShow = createSelector(
    state => state.enrolmentsGrid.columnsToHide,
    getBaseColumns,
    (columnsToHide, baseColumns) => baseColumns.filter(column => !columnsToHide.includes(column.id || column.accessor)),
);

const mapStateToProps = state => ({
    isCec: roles.CEC === state.role,
    columnsToHide: state.enrolmentsGrid.columnsToHide,
    baseColumns: getBaseColumns(state),
    columnsToShow: getColumnsToShow(state),
    allVisibleRowsSelected: getAreAllVisibleRowsSelected(state),
});

const mapDispatchToProps = (dispatch) => ({
    hideEnrolmentsGridColumn: column => dispatch(hideEnrolmentsGridColumn(column)),
    showEnrolmentsGridColumn: column => dispatch(showEnrolmentsGridColumn(column)),
    showAllEnrolmentsGridColumns: () => dispatch(showAllEnrolmentsGridColumns()),
    resetEnrolmentsGridFilterAndSort: () => dispatch(resetEnrolmentsGridFilterAndSort()),
    downloadEnrolmentsCsv: () => dispatch(downloadEnrolmentsCsv()),
    clearEnrolmentsGridRowsSelected: () => dispatch(clearEnrolmentsGridRowsSelected()),
    setEnrolmentsGridAllVisibleRowsSelected: () => dispatch(setEnrolmentsGridAllVisibleRowsSelected()),
});

const EnrolmentsSection = ({resetEnrolmentsGridFilterAndSort, hideEnrolmentsGridColumn, showEnrolmentsGridColumn, showAllEnrolmentsGridColumns, downloadEnrolmentsCsv, baseColumns, columnsToHide, columnsToShow, clearEnrolmentsGridRowsSelected, setEnrolmentsGridAllVisibleRowsSelected, allVisibleRowsSelected}) => {

    const formatOptionLabel = ({ value, label }) => (
        <div>
            <CheckboxField
                label={label}
                checked={!columnsToHide.includes(value) && 'ALL' !== value }
            />
        </div>
    );

    return <React.Fragment>
        <EnrolmentsAndCohortsPollingAlertMessage />
        <div className="d-block d-md-flex mb-3 mt-4">
            <div className="col-md-3 position-relative px-0 no-padding me-2">
                <SelectInput
                    id="o-columns"
                    placeholder="Hide and show columns"
                    isMulti={true}
                    overrideOnChange={(selectedOptions, {option: {value}}) => {
                        if (value === 'ALL') {
                            showAllEnrolmentsGridColumns();
                            return;
                        }

                        !columnsToHide.includes(value)
                            ? hideEnrolmentsGridColumn(value)
                            : showEnrolmentsGridColumn(value);
                    }}
                    options={
                        (
                            0 === columnsToHide.length
                            ? []
                            : [{label: 'Show All', value: 'ALL'}]
                        ).concat(
                            baseColumns.map(({accessor, Header}) => ({label: Header, value: accessor}))
                        )
                    }
                    {...{formatOptionLabel}}
                    styles={
                        {
                            container: (provided) => ({
                                ...provided,
                                height: '100%',
                            }),
                            control: (provided) => ({
                                ...provided,
                                height: '100%',
                            })
                        }
                    }
                />
            </div>
            <div className="text-justify mt-3 mt-md-auto ms-md-auto mb-n2">
                <button className="cec-btn cec-btn-secondary-teal me-2 mb-2" data-cy="select/unselect-all-button" onClick={
                    allVisibleRowsSelected ? clearEnrolmentsGridRowsSelected : setEnrolmentsGridAllVisibleRowsSelected
                }>
                    {allVisibleRowsSelected ? 'Unselect all' : 'Select all'}
                </button>
                <button className="cec-btn cec-btn-secondary-teal me-2 mb-2" data-cy="enrolments--reset-table-button" onClick={resetEnrolmentsGridFilterAndSort}>
                    Reset table
                </button>
                <button className="cec-btn cec-btn-primary-teal mb-2" data-cy="download-csv-button" onClick={downloadEnrolmentsCsv}>
                    Download CSV
                </button>
            </div>
        </div>
        <EnrolmentsGrid columns={columnsToShow}/>
    </React.Fragment>;
};

export default connect(mapStateToProps, mapDispatchToProps)(EnrolmentsSection);
