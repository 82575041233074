import React from "react";
import Tippy from "@tippyjs/react";

export default ({openModal, deleteRow = () => null, row: {original: {id, enrolments}}}) => {

    return <div className="body__cell cell--actions">
        <button className="link-teal-to-gray button-link" data-cy="cohorts-edit" onClick={() => openModal(id)}>
            Edit
        </button>
        <Tippy
            content="Remove enrolments before deleting"
            placement="top"
            animation="fade"
            arrow theme="light-border"
            appendTo={() => document.body}
            disabled={0 === enrolments}
        >
            <div className="ms-2">
                <button className="link-teal-to-gray button-link" data-cy="cohorts-delete" disabled={0 !== enrolments} onClick={() => deleteRow(id)}>
                    Delete
                </button>
            </div>
        </Tippy>
    </div>;
}
