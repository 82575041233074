import EnrolmentModalSectionTitle from "../../../../molecules/enrolments/EnrolmentModalSectionTitle";
import {Collapse} from "reactstrap";
import BookOpenOutlineIcon from "mdi-react/BookOpenOutlineIcon";
import AccountMultipleIcon from "mdi-react/AccountMultipleIcon";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import PlaceIcon from "mdi-react/PlaceIcon";
import React, {useState} from "react";
import inputs from "../../../../molecules/inputsWithContextAndSurrounds";
import fields from "../../../../../redux/fields/enrolment";
import ReadOnlyField from "../../../../atoms/ReadOnlyField";
import Tippy from "@tippyjs/react";
import {dateFromText} from '../../../../atoms/DateInput';
import MutedText from "../../../../atoms/MutedText";
import {components} from 'react-select';
import WarningIcon from "../../../../atoms/WarningIcon";

const {CheckboxInput, DateInput, SelectInput} = inputs;

const filterOption = ({label, value, data, data: {providerName}}, searchString) => {
    if (label.toLowerCase().includes(String(searchString).toLowerCase()) || providerName.toLowerCase().includes(String(searchString).toLowerCase())) {
        return true;
    }
};

const startOfPreviousMonthDate = (() => { const d = new Date(); d.setDate(0); d.setDate(1); return d; })();
const endOfCurrentMonthDate = (() => { const d = new Date(); return new Date(d.getFullYear(), d.getMonth() + 1, 0) })();

const TOP_UP_COURSE_WITHOUT_PREVIOUS_COURSE_WARNING_TEXT = 'This course is a top-up course. However, the Careers Lead does not have a previously completed course registered in the system.';

const WarnsAboutTopUpCourseOption = (props) => {
    if (!props.data.isTopUp) {
        return <components.Option {...props} />;
    }

    return (
        <Tippy
            content={TOP_UP_COURSE_WITHOUT_PREVIOUS_COURSE_WARNING_TEXT}
            placement="top"
            animation="fade"
            arrow theme="light-border"
        >
            <span>
                <components.Option {...props}>
                    <WarningIcon text={props.label}/>
                </components.Option>
            </span>
        </Tippy>
    );
};

export default ({isCec, courseOptions, deliveryTypeOptions, chosenCourseProviderName, cohortOptions, regionOptions, isContractSigned, contractSignedAt, fundDateRangeFromContractSignedDate, hasFixedTransactions, schoolAtContractSignedDate, loggedInProviderName, schoolStartedAt, isEnrolmentLockedDueToCompleted, isEnrolmentLockedDueToReleased, isEnrolmentLockedDueToContractSigned, isChosenCourseATopUpCourse, careersLeadHasOtherCompletedEnrolments}) => {
    const [collapse, setCollapse] = useState(true);

    const schoolNameAtContractSignedDate = schoolAtContractSignedDate.schoolName || '';
    const fullSchoolNameAtContractSignedDate = schoolAtContractSignedDate.schoolName
        ? `${schoolAtContractSignedDate.urn} - ${schoolNameAtContractSignedDate}`
        : '';
    const maxSchoolNameChars = 55;
    const contractSignedAtDate = contractSignedAt
        ? dateFromText(contractSignedAt)
        : null;
    const contractSignedMaxDate = hasFixedTransactions
        ? new Date(Math.min(endOfCurrentMonthDate, fundDateRangeFromContractSignedDate.endDate))
        : endOfCurrentMonthDate;
    const topUpCourseWithoutPreviousCourse = isChosenCourseATopUpCourse && !careersLeadHasOtherCompletedEnrolments;

    return <div className="mb-5">
        <EnrolmentModalSectionTitle
            title={'Course Information'}
            {...{collapse, setCollapse}}
        />
        <Collapse isOpen={collapse}>
            <div className="bg-light py-3 px-4 px-md-5">
                <div className="row">
                    <div className="col-12 col-md-6 position-relative mb-3" data-cy="enrolment--course">
                        <SelectInput id="o-course" warning={topUpCourseWithoutPreviousCourse ? 'Top-up course without previous course' : null} warningTooltip={topUpCourseWithoutPreviousCourse ? TOP_UP_COURSE_WITHOUT_PREVIOUS_COURSE_WARNING_TEXT : null} components={{ Option: !careersLeadHasOtherCompletedEnrolments ? WarnsAboutTopUpCourseOption : components.Option }} label="Course" placeholder="Select course" icon={<BookOpenOutlineIcon />} options={courseOptions} isGrouped={isCec} field={fields.COURSE_ID} disabled={isEnrolmentLockedDueToCompleted || isEnrolmentLockedDueToReleased} required {...{filterOption}} />
                    </div>
                    <div className="col-md-6 position-relative mb-3">
                        <ReadOnlyField value={isCec ? (chosenCourseProviderName ? chosenCourseProviderName : '') : loggedInProviderName} label="Provider"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 position-relative mb-3" data-cy="enrolment--training-delivery-type">
                        <SelectInput id="o-deliveryType" label="Training Delivery Type" placeholder="Select training delivery type" icon={<BookOpenOutlineIcon />} options={deliveryTypeOptions} field={fields.DELIVERY_TYPE_ID} disabled={isEnrolmentLockedDueToCompleted || isEnrolmentLockedDueToReleased} required />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 position-relative mb-3" data-cy="enrolment--course-delivery-region">
                        <SelectInput id="o-region" label="Course Delivery Region" placeholder="Select region" icon={<PlaceIcon />} options={regionOptions} field={fields.REGION_ID} disabled={isEnrolmentLockedDueToCompleted || isEnrolmentLockedDueToReleased} required />
                    </div>
                    <div className="col-12 col-md-6 position-relative mb-3" data-cy="enrolment--cohort">
                        <SelectInput id="o-cohort" label="Cohort" placeholder="Select cohort" icon={<AccountMultipleIcon />} options={cohortOptions} field={fields.COHORT_ID} disabled={isEnrolmentLockedDueToCompleted || isEnrolmentLockedDueToReleased} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 position-relative mb-3">
                        <div className="form__group">
                            <p className="form__label">Contract Issued?</p>
                            <CheckboxInput id="o-contractIssued" checkboxLabel="Yes" data-cy="enrolment--contract-issued" field={fields.IS_CONTRACT_ISSUED} disabled={isEnrolmentLockedDueToCompleted || isEnrolmentLockedDueToReleased} />
                        </div>
                    </div>
                    {isCec && (
                        <div className="col-12 col-md-6 position-relative mb-3" data-cy="enrolment--contract-issued-date">
                            <DateInput id="o-contractIssuedDate" label="Contract Issued Date" placeholder="Select the contract issued date" field={fields.CONTRACT_ISSUED_AT} icon={<CalendarBlankIcon />} disabled={isEnrolmentLockedDueToCompleted || isEnrolmentLockedDueToReleased} isClearable />
                        </div>
                    )}
                </div>
                <div className="row">
                    <Tippy
                        content="You have already received the contract signed payment for this Careers Leader. You will need to withdraw or release this Careers Leader from the training if you want to change the period or wave that they are completing training in."
                        placement="top"
                        animation="fade"
                        arrow theme="light-border"
                        disabled={!isEnrolmentLockedDueToContractSigned}
                    >
                        <div className="col-12 col-md-6 position-relative mb-3" data-cy="enrolment--contract-signed-date">
                            <DateInput id="o-sltSignedDate" label="SLT Contract Signed Date" placeholder="Select the SLT contract signed date" field={fields.CONTRACT_SIGNED_AT} disabled={isEnrolmentLockedDueToCompleted || isEnrolmentLockedDueToReleased} minDate={hasFixedTransactions && fundDateRangeFromContractSignedDate.startDate} maxDate={contractSignedMaxDate} isClearable={!hasFixedTransactions} icon={<CalendarBlankIcon />} />
                        </div>
                    </Tippy>
                    <div className="row col-12 col-md-6 position-relative fade-in-bottom">
                        <Tippy
                            content={schoolNameAtContractSignedDate}
                            placement="top"
                            animation="fade"
                            arrow theme="light-border"
                            appendTo={() => document.body}
                            disabled={!isContractSigned || schoolNameAtContractSignedDate.length <= maxSchoolNameChars}
                        >
                            <div className="col-md-12 position-relative mb-3">
                                <ReadOnlyField value={isContractSigned ? `${schoolNameAtContractSignedDate.length <= maxSchoolNameChars ? fullSchoolNameAtContractSignedDate : `${fullSchoolNameAtContractSignedDate.substr(0, maxSchoolNameChars)}...`}` : ''} label="School At Contract Sign Date"/>
                            </div>
                        </Tippy>
                    </div>
                </div>
                <div className="row">
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 position-relative mb-3" data-cy="enrolment--start-date">
                        <DateInput id="o-startDate" label="Start Date" placeholder="Select the start date" field={fields.START_DATE} icon={<CalendarBlankIcon />} disabled={isEnrolmentLockedDueToCompleted || isEnrolmentLockedDueToReleased || !isContractSigned} minDate={contractSignedAtDate} isClearable />
                        { !isContractSigned &&
                            <MutedText text={"You must enter the ‘SLT Contract Signed Date’ before you can complete this field"} />
                        }
                    </div>
                    <div className="col-12 col-md-6 position-relative mb-3" data-cy="enrolment--completion-date">
                        <DateInput id="o-thFinishDate" label="Theoretical Completion Date" placeholder="Select the completion date" field={fields.THEORETICAL_COMPLETION_DATE} disabled={isEnrolmentLockedDueToCompleted || isEnrolmentLockedDueToReleased || !isContractSigned} icon={<CalendarBlankIcon />} minDate={contractSignedAtDate} isClearable />
                        { !isContractSigned &&
                            <MutedText text={"You must enter the ‘SLT Contract Signed Date’ before you can complete this field"} />
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 position-relative mb-3">
                        <div className="form__group">
                            <p className="form__label">Completed Course?</p>
                            <CheckboxInput id="o-completedCourse" checkboxLabel="Yes" data-cy="enrolment--completed-course" field={fields.IS_COMPLETED} disabled={isEnrolmentLockedDueToReleased} />
                        </div>
                    </div>
                    {isCec && (
                        <div className="col-12 col-md-6 position-relative mb-3" data-cy="enrolment--completed-course-date">
                            <DateInput id="o-completedCourseDate" label="Real Completed Date" placeholder="Select the real completed date" field={fields.COMPLETED_AT} icon={<CalendarBlankIcon />} minDate={startOfPreviousMonthDate} maxDate={endOfCurrentMonthDate} disabled={isEnrolmentLockedDueToReleased} isClearable />
                        </div>
                    )}
                </div>
            </div>
        </Collapse>
    </div>;
}
