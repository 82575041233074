import React from "react";
import {connect} from "react-redux";
import signals from "../../redux/actions/signals";

const {
    tryToStopImpersonating,
} = signals.actionCreators;

const mapStateToProps = (state) => ({
    isImpersonating: state.impersonation.isImpersonating,
    impersonatedUserFullName: `${state.data.profile.firstName} ${state.data.profile.lastName}`,
});

const mapDispatchToProps = (dispatch) => ({
    tryToStopImpersonating: () => dispatch(tryToStopImpersonating()),
});

const ImpersonationWrapper = ({isImpersonating, impersonatedUserFullName, tryToStopImpersonating, children}) => <>
    {
        isImpersonating
            ? (
                <div className="impersonation_wrapper">
                    <div className="impersonation_row">
                        <div className="impersonation_element-wrapper" >
                            <p className="mb-0">Impersonating <span className="font-weight-bold">{impersonatedUserFullName}</span></p>
                        </div>
                        <div className="impersonation_element-wrapper" >
                            <button className="cec-btn cec-btn-primary-teal ms-auto" onClick={tryToStopImpersonating}>Back to Dashboard</button>
                        </div>
                    </div>

                    {children}
                </div>
            ) : (
                <>
                    {children}
                </>
            )
    }
</>;

export default connect(mapStateToProps, mapDispatchToProps)(ImpersonationWrapper);
