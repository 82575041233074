import React from "react";
import {connect} from "react-redux";
import roles from "../../redux/roles";
import routes from "../../redux/routes";
import LogoutIcon from "mdi-react/LogoutIcon";

const mapStateToProps = (state, {title, description}) => ({
    title,
    description,
    profile: state.data.profile,
    provider: state.provider,
    role: state.role,
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch({
        type: routes.LOGOUT,
    }),
});

const WelcomeSection = ({role, provider, profile: {firstName, lastName}, title, description, logout}) => {

    const greetingName = [roles.CEC, roles.PROVIDER].includes(role)
        ? 'CEC' === role
            ? 'CEC Admin'
            : `${provider.name} Admin`
        : `${firstName} ${lastName}`;

    return <div className="section__block">
        <div className="d-flex">
            <div className="my-auto">
                <h1 className="font-20 font-weight-bold mb-3">{title}</h1>
                <p className="mb-0" data-cy="logged-in-name">You have logged in as <strong>{greetingName}</strong>.</p>
            </div>
            <div className="ms-auto">
                <button className="cec-btn cec-btn-primary-teal d-flex" data-cy="logout-button" onClick={logout}>
                    <span className="d-none d-sm-block my-auto me-2">Log out</span> <LogoutIcon className="my-auto" size={18} />
                </button>
            </div>
        </div>
        <div className="text-muted border-top pt-4 mt-4 mb-0">
            {description}
        </div>
    </div>;

};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeSection);
