import React from "react";
import InformationOutlineIcon from "mdi-react/InformationOutlineIcon";

const AlertMessage = ({type = 'success', icon = <InformationOutlineIcon />, flex = false, children}) => {
    return (
        <div className={`alert alert-cec-${type} fade-in-bottom mb-0`}>
            <div className="d-flex">
                <div className="border-right d-none d-sm-block border-info my-auto me-3 pe-3">
                    {icon}
                </div>
                <div className={`my-auto${flex ? ' d-flex align-items-center w-100' : ''}`}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AlertMessage;
