import React from "react";
import {Modal, ModalFooter} from "reactstrap";

import CloseIcon from "mdi-react/CloseIcon";

const ModalMessage = ({children, size = "md", additionalClasses, title, isOpen, isRequesting=false, additionalButtons, closeModal = () => null, submitForm = null, overrideSaveButtonText, overrideCancelButtonText}) => {

    const closeIfOpen = () => isOpen && closeModal();

    return (
        <React.Fragment>
            <Modal size={size} className={`default-modal${additionalClasses && ` ${additionalClasses}`}`} isOpen={isOpen} toggle={closeIfOpen}>
                <div className="modal-header">
                    <h5 className="modal-title">
                        {title}
                    </h5>
                    <div className="buttons">
                        <button className="button-link close-modal" onClick={closeModal}>
                            <CloseIcon />
                        </button>
                    </div>
                </div>
                <div className="p-4 p-md-5">
                    {children}
                </div>
                <ModalFooter>
                    {additionalButtons}
                    <button type="button" className="cec-btn cec-btn-secondary-teal me-2" data-cy="modal-cancel-button" disabled={isRequesting} onClick={() => closeModal()}>{overrideCancelButtonText || 'Cancel'}</button>
                    {null !== submitForm && <button type="button" className="cec-btn cec-btn-primary-teal" data-cy="modal-save-changes-button" disabled={isRequesting} onClick={submitForm}>{overrideSaveButtonText || 'Save Changes'}</button>}
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
};

export default ModalMessage;
