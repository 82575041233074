import EnrolmentModalSectionTitle from "../../../../molecules/enrolments/EnrolmentModalSectionTitle";
import {Collapse} from "reactstrap";
import AccountIcon from "mdi-react/AccountIcon";
import TrophyAwardIcon from "mdi-react/TrophyAwardIcon";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import React, {useState} from "react";
import inputs from "../../../../molecules/inputsWithContextAndSurrounds";
import fields from "../../../../../redux/fields/enrolment";
import MutedText from "../../../../atoms/MutedText";

const {DateInput, SelectInput} = inputs;

export default ({isCec, statusOptions, isContractSigned, isEnrolmentLockedDueToCompleted, isEnrolmentLockedDueToReleased}) => {
    const [collapse, setCollapse] = useState(true);

    return <div>
        <EnrolmentModalSectionTitle
            title={'Status & Results Information'}
            {...{collapse: isContractSigned && collapse, setCollapse, disabled: !isContractSigned}}
        />
        <Collapse isOpen={isContractSigned && collapse}>
            <div className="bg-light py-3 px-4 px-md-5">
                <div className="row">
                    <div className="col-12 col-md-6 position-relative mb-3" data-cy="enrolment--status">
                        <SelectInput id="o-status" label="Status" isClearable={false} placeholder="Select the status" icon={<AccountIcon />} field={fields.STATUS} options={statusOptions} disabled={isEnrolmentLockedDueToCompleted || isEnrolmentLockedDueToReleased} required />
                    </div>
                    {isCec && (
                        <div className="col-12 col-md-6 position-relative mb-3">
                            <DateInput id="o-statusDate" label="Status Date" isClearable={false} placeholder="Select the status date" icon={<CalendarBlankIcon />} field={fields.STATUS_UPDATED_AT} disabled={isEnrolmentLockedDueToReleased} />
                        </div>
                    )}
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 position-relative mb-3" data-cy="enrolment--result">
                        <SelectInput id="o-result" label="Result" placeholder="Select the result" icon={<TrophyAwardIcon />} field={fields.ASSESSMENT_OUTCOME} options={[{label: 'Passed', value: 'PASS'}, {label: 'Failed', value: 'FAIL'}]} disabled={isEnrolmentLockedDueToReleased || !isEnrolmentLockedDueToCompleted} />
                        { !isEnrolmentLockedDueToCompleted &&
                            <MutedText text={"You must confirm the Careers Leader has Completed the course before you can complete this field"} />
                        }
                    </div>
                    {isCec && (
                        <div className="col-12 col-md-6 position-relative mb-3">
                            <DateInput id="o-resultDate" label="Result Date" isClearable={true} placeholder="Select the result date" icon={<CalendarBlankIcon />} field={fields.ASSESSMENT_OUTCOME_UPDATED_AT} disabled={isEnrolmentLockedDueToReleased || !isEnrolmentLockedDueToCompleted} />
                        </div>
                    )}
                </div>
            </div>
        </Collapse>
    </div>;
}
