import React from "react";
import {connect} from "react-redux";
import deltas from "../../../redux/actions/deltas";
import signals from "../../../redux/actions/signals";
import SelectInput from "../../atoms/SelectInput";
import careersLeadSearchStates from "../../../redux/states/careersLeadSearch";

const {
    setUserIdToImpersonate,
    clearUserIdToImpersonate,
    clearCareersLeadSearchResults,
} = deltas.actionCreators;

const {
    tryToStartImpersonating,
    searchCareersLeads,
} = signals.actionCreators;

const mapStateToProps = state => ({
    userIdToImpersonate: state.impersonation.userIdToImpersonate,
    careersLeadOptions: state.data.careersLeadSearchResults.results && state.data.careersLeadSearchResults.results.map(
        careersLead => ({
            label: `${careersLead.firstName} ${careersLead.lastName} - ${careersLead.email} - ${careersLead.institutionUrn} - ${careersLead.institutionName}`,
            value: careersLead.uid,
        })
    ),
    isSearchingForCareersLeads: careersLeadSearchStates.REQUESTING === state.states.careersLeadSearch.state,
});

const mapDispatchToProps = (dispatch) => ({
    setUserIdToImpersonate: (userIdToImpersonate) => dispatch(setUserIdToImpersonate(userIdToImpersonate)),
    clearUserIdToImpersonate: () => dispatch(clearUserIdToImpersonate()),
    tryToStartImpersonating: () => dispatch(tryToStartImpersonating()),
    searchCareersLeads: (query) => dispatch(searchCareersLeads(query)),
    clearCareersLeadSearchResults: () => dispatch(clearCareersLeadSearchResults()),
});

const ImpersonationSection = ({careersLeadOptions, searchCareersLeads, isSearchingForCareersLeads, clearCareersLeadSearchResults, userIdToImpersonate, setUserIdToImpersonate, clearUserIdToImpersonate, tryToStartImpersonating}) => <>
    <div className="my-4">
        <p className="text-muted border-bottom pb-4 mt-4 mb-0">
            You can use this section to impersonate a user and make changes to their profile.
        </p>
    </div>
    <div className="d-block d-md-flex mb-3 mt-4">
        <div className="col-md-5 position-relative px-0 my-auto no-padding">
            <SelectInput
                id="o-columns"
                placeholder="Start typing to find a Careers Leader to impersonate"
                overrideOnChange={(selectedOption, {action}) => {
                    switch (action) {
                        case 'select-option':
                            setUserIdToImpersonate(selectedOption.value);
                        break;
                        case 'clear':
                            clearUserIdToImpersonate();
                    }
                }}
                options={careersLeadOptions}
                value={userIdToImpersonate}
                onInputChange={e => {searchCareersLeads(e);}}
                isLoading={isSearchingForCareersLeads}
                onBlur={() => null === userIdToImpersonate && clearCareersLeadSearchResults()}
                filterOption={() => true}
            />
        </div>
        <div className="text-center mt-3 my-md-auto mx-4">
            <button className="cec-btn cec-btn-primary-teal" data-cy="impersonate-button" onClick={tryToStartImpersonating} disabled={null === userIdToImpersonate}>
                Impersonate
            </button>
        </div>
    </div>
</>;

export default connect(mapStateToProps, mapDispatchToProps)(ImpersonationSection);
