import React from "react";
import LoadingIcon from "mdi-react/LoadingIcon";
import AlertMessage from "../atoms/AlertMessage";

const PollingAlertMessage = ({isLoading, showAlert, message, buttonOnClick, buttonText='Reload data'}) => {
    return (
        <div className="mb-3 mt-4">
            {
                showAlert &&
                <AlertMessage
                    icon={isLoading ? <LoadingIcon className="more__icon animation--spin"/> : undefined}
                    flex
                >
                    {message}
                    {
                        buttonOnClick &&
                        <button className="cec-btn cec-btn-primary-teal ms-auto" onClick={buttonOnClick}>
                            {buttonText}
                        </button>
                    }
                </AlertMessage>
            }
        </div>
    )
};

export default PollingAlertMessage;
