import React from "react";

import AlertCircleOutlineIcon from "mdi-react/AlertCircleOutlineIcon";

export default ({error, errorTextToComponentReplacements}) =>
        Array.isArray(error)
            ? <>{error.map(err => <Error error={err} {...{errorTextToComponentReplacements}} />)}</>
            : <Error {...{error, errorTextToComponentReplacements}} />

const Error = ({error, errorTextToComponentReplacements}) => <div className="fade-in-bottom d-flex">
    <div className="my-auto">{
        Array.isArray(error)
            ? <div>{error.map(err => <div>{err}</div>)}</div>
            : applyReplacements([error], errorTextToComponentReplacements ?? {})
    }</div>
    <div className="my-auto ms-auto"><AlertCircleOutlineIcon size={18} /></div>
</div>;

const applyReplacements = (arrayOfElements, textToComponentReplacements) => {
    const textToComponentReplacementsEntries = Object.entries(textToComponentReplacements);

    for (let i = 0; i < arrayOfElements.length; i++) {
        if ('string' !== typeof arrayOfElements[i]) {
            continue;
        }

        textToComponentReplacementsEntries.forEach(([textToReplace, ComponentReplacement]) => {
            const element = arrayOfElements[i];

            if ('string' !== typeof element) {
                return;
            }

            const textToReplaceIndex = element.indexOf(textToReplace);

            if (-1 === textToReplaceIndex) {
                return;
            }

            const textBeforeReplacement = element.slice(0, textToReplaceIndex);
            const textAfterReplacement = element.slice(textToReplaceIndex + textToReplace.length);

            arrayOfElements.splice(i, 1, [textBeforeReplacement, ComponentReplacement, textAfterReplacement]);
        });
    }

    return arrayOfElements;
}
