import ProfileSectionTitle from "../../molecules/careers-lead-profile/ProfileSectionTitle";
import ProfileSectionTitleButton from "../../atoms/careers-lead-profile/ProfileSectionTitleButton";
import React from "react";
import LockedProfileAlertMessage
    from "../../molecules/careers-lead-profile/course-information-section/LockedProfileAlertMessage";
import IndependentSchoolAlertMessage
    from "../../molecules/careers-lead-profile/course-information-section/IndependentSchoolAlertMessage";
import StartEditingAlertMessage
    from "../../molecules/careers-lead-profile/course-information-section/StartEditingAlertMessage";
import EnrolmentInformationBox
    from "../../molecules/careers-lead-profile/course-information-section/EnrolmentInformationBox";
import PreviouslyCompletedCourseAlertMessage
    from "../../molecules/careers-lead-profile/course-information-section/PreviouslyCompletedCourseAlertMessage";

const CourseInformationSection = ({profile, enrolmentsMappedAndOrderedMostRecentFirst, openEnrolmentIndex, setOpenEnrolmentIndex, openCourseInformationModal}) => {
    const currentEnrolment = 0 !== enrolmentsMappedAndOrderedMostRecentFirst.length
        ? enrolmentsMappedAndOrderedMostRecentFirst[0]
        : null
    ;

    return <div className="col-12 col-md-6 d-flex position-relative">
        <div className="section__block">
            <ProfileSectionTitle
                title={'Select Your Course'}
                button={
                    <ProfileSectionTitleButton
                        data-cy={'select-your-course-button'}
                        onClick={openCourseInformationModal}
                        disabled={profile.isLocked}
                        text={'Select'}
                    />
                }
            />
            {profile.isIndependentSchool && <IndependentSchoolAlertMessage />}
            {currentEnrolment && currentEnrolment.isComplete && <PreviouslyCompletedCourseAlertMessage/>}
            {profile.isLocked && <LockedProfileAlertMessage/>}
            <div className="mb-4">
                {(null === currentEnrolment) && !profile.isLocked && <StartEditingAlertMessage/>}
                {
                    enrolmentsMappedAndOrderedMostRecentFirst.map((enrolment, i) =>
                        <EnrolmentInformationBox key={i} mappedEnrolment={enrolment} isOpen={i === openEnrolmentIndex} setOpen={() => setOpenEnrolmentIndex(i)} isMostRecent={0 === i}/>
                    )
                }
            </div>
        </div>
    </div>;
}

export default CourseInformationSection;
