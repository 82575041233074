import React from "react";

export default ({onClick, disabled, text='Edit', ...rest}) => {
    const dataCy = rest['data-cy'];
    return <button
        onClick={onClick}
        data-cy={dataCy}
        className="cec-btn cec-btn-primary-teal ms-auto my-auto"
        disabled={disabled}
    >
        {text}
    </button>;
}
