import FailedPaymentProcessesGrid from "../FailedPaymentProcessesGrid";
import React from "react";
import deltas from "../../../redux/actions/deltas";
import {connect} from "react-redux";
import CheckboxField from "../../atoms/CheckboxField";
import SelectInput from "../../atoms/SelectInput";
import {createSelector} from 'reselect';
import FailedPaymentProcessesPollingAlertMessage from "../../molecules/FailedPaymentProcessesPollingAlertMessage";

const allColumns = [
    { accessor: 'processType', Header: 'Process Type' },
    {
        id: 'additionalData',
        Header: 'Additional Data',
        accessor: (row) => <>{row.additionalData.map(
                        (additionalDatum) => <div>
                            <span><strong>{additionalDatum['friendlyName']}:</strong></span>&nbsp;<span>{additionalDatum['value']}</span>
                        </div>)}</>,
        disableFilters: true,
    },
    { accessor: 'entityId', Header: 'Entity Id' },
    { accessor: 'reason', Header: 'Reason' },
    { id: 'failedAt', Header: 'Failed At', accessor: (row) => (new Date(row.failedAt)).toISOString().match(/(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2})/).slice(1,3).join(' ') },
];

const {
    hideFailedPaymentProcessesGridColumn,
    showFailedPaymentProcessesGridColumn,
    showAllFailedPaymentProcessesGridColumns,
    resetFailedPaymentProcessesGridFilterAndSort,
    clearFailedPaymentProcessesGridRowsSelected,
    setFailedPaymentProcessesGridAllVisibleRowsSelected,
} = deltas.actionCreators;

const getAreAllVisibleRowsSelected = createSelector(
    state => state.failedPaymentProcessesGrid.filteredSortedData,
    state => state.failedPaymentProcessesGrid.selectedRows,
    (filteredSortedData, selectedRows) => filteredSortedData.map(row => row.id).every(id => selectedRows.includes(id)));

const getColumnsToShow = createSelector(
    state => state.failedPaymentProcessesGrid.columnsToHide,
    (columnsToHide) => allColumns.filter(column => !columnsToHide.includes(column.accessor)),
);

const mapStateToProps = state => ({
    columnsToHide: state.failedPaymentProcessesGrid.columnsToHide,
    baseColumns: allColumns,
    columnsToShow: getColumnsToShow(state),
    allVisibleRowsSelected: getAreAllVisibleRowsSelected(state),
});

const mapDispatchToProps = (dispatch) => ({
    hideFailedPaymentProcessesGridColumn: column => dispatch(hideFailedPaymentProcessesGridColumn(column)),
    showFailedPaymentProcessesGridColumn: column => dispatch(showFailedPaymentProcessesGridColumn(column)),
    showAllFailedPaymentProcessesGridColumns: () => dispatch(showAllFailedPaymentProcessesGridColumns()),
    resetFailedPaymentProcessesGridFilterAndSort: () => dispatch(resetFailedPaymentProcessesGridFilterAndSort()),
    clearFailedPaymentProcessesGridRowsSelected: () => dispatch(clearFailedPaymentProcessesGridRowsSelected()),
    setFailedPaymentProcessesGridAllVisibleRowsSelected: () => dispatch(setFailedPaymentProcessesGridAllVisibleRowsSelected()),
});

const FailedPaymentProcessesSection = ({resetFailedPaymentProcessesGridFilterAndSort, hideFailedPaymentProcessesGridColumn, showFailedPaymentProcessesGridColumn, showAllFailedPaymentProcessesGridColumns, baseColumns, columnsToHide, columnsToShow}) => {

    const formatOptionLabel = ({ value, label }) => (
        <div>
            <CheckboxField
                label={label}
                checked={!columnsToHide.includes(value) && 'ALL' !== value }
            />
        </div>
    );

    return <React.Fragment>
        <FailedPaymentProcessesPollingAlertMessage />
        <div className="d-block d-md-flex mb-3 mt-4">
            <div className="col-md-3 position-relative px-0 my-auto no-padding">
                <SelectInput
                    id="o-columns"
                    placeholder="Hide and show columns"
                    isMulti={true}
                    overrideOnChange={(selectedOptions, {option: {value}}) => {
                        if (value === 'ALL') {
                            showAllFailedPaymentProcessesGridColumns();
                            return;
                        }

                        !columnsToHide.includes(value)
                            ? hideFailedPaymentProcessesGridColumn(value)
                            : showFailedPaymentProcessesGridColumn(value);
                    }}
                    options={
                        (
                            0 === columnsToHide.length
                                ? []
                                : [{label: 'Show All', value: 'ALL'}]
                        ).concat(
                            baseColumns.map(({accessor, Header}) => ({label: Header, value: accessor}))
                        )
                    }
                    {...{formatOptionLabel}}
                />
            </div>
            <div className="text-center mt-3 my-md-auto ms-md-auto">
                <button className="cec-btn cec-btn-secondary-teal me-2" onClick={resetFailedPaymentProcessesGridFilterAndSort}>
                    Reset table
                </button>
            </div>
        </div>
        <FailedPaymentProcessesGrid columns={columnsToShow}/>
    </React.Fragment>;
};

export default connect(mapStateToProps, mapDispatchToProps)(FailedPaymentProcessesSection);
